import { useEffect, useMemo, useState } from 'react';

// storyblok specifics
import { addBridge, get, initEventListeners } from '@lib/storyblok';

// utils
import { isObject, safeJsonParse } from '@utils/share';

// types
import type { Sb } from '@types';

const isPreviewBranch = process.env.GATSBY_STORYBLOK_BRANCH_NAME === 'PREVIEW';

function useStoryblok__Deprecated(
  currPath: string,
  gqlStory?: Sb.GraphQLStoryNode,
): [Sb.Story | undefined, (story: Sb.Story) => void] {
  const [story, setStory] = useState<Sb.Story | undefined>(
    isStory(gqlStory)
      ? {
          ...gqlStory,
          // content is initially a string, parse it for valid json data
          content: parseContent(gqlStory.content),
        }
      : undefined,
  );

  const updateStory = (story: Sb.Story): void => {
    setStory(story);
  };

  useEffect(() => {
    let isCancelled = false;

    async function getInitialStory() {
      if (!isPreview() || !isPreviewBranch || process.env.NODE_ENV === 'production') return;

      const [storyFromCdn, error] = await get({
        query: {
          slug: `cdn/stories/${story ? story.full_slug : currPath}`,
          from_release: getReleaseID(),
        },
      });

      if (storyFromCdn && !error && !isCancelled) {
        setStory(storyFromCdn);
      }

      addBridge(() => {
        initEventListeners(storyFromCdn, (updatedStory) => {
          if (!isCancelled) setStory(updatedStory);
        });
      });
    }

    getInitialStory();

    return () => {
      isCancelled = true;
    };
  }, []);

  return [story, updateStory];
}

export default function useStoryblok(
  pageCtx: { story: Sb.GraphQLStoryNode },
  currPath?: string,
): [Sb.Story | undefined, (story: Sb.Story) => void] {
  const [story, setStory] = useState<Sb.Story>({
    ...pageCtx.story,
    content: parseContent(pageCtx.story.content),
  });

  const updateStory = (story: Sb.Story): void => {
    setStory(story);
  };

  useEffect(() => {
    let isCancelled = false;

    async function getInitialStory() {
      if (isPreview()) {
        const [storyFromCdn, error] = await get({
          query: {
            slug: `cdn/stories/${story ? story.full_slug : currPath}`,
            from_release: getReleaseID(),
          },
        });

        if (storyFromCdn && !error && !isCancelled) {
          setStory(storyFromCdn);
        }

        addBridge(() => {
          initEventListeners(storyFromCdn, (updatedStory) => {
            if (!isCancelled) setStory(updatedStory);
          });
        });
      }
    }

    getInitialStory();

    return () => {
      isCancelled = true;
    };
  }, []);

  return [story, updateStory];
}

function getReleaseID() {
  return extractFromURL(window.location.href, '_storyblok_release');
}

export function extractFromURL(url: string, phrase: string): string {
  const extractedValue = new URL(url).searchParams.get(phrase);

  return extractedValue || '';
}

function isContent(o: any): o is Sb.StoryContent {
  return [`_uid`, 'component'].every((prop) => prop in o);
}

function parseContent(content: string): Sb.StoryContent {
  // we're using safeJson to add typing to json.parse content
  const { hasError, parsed } = safeJsonParse(isContent)(content);

  // in the event of fail, return valid parse `content` with empty values
  if (hasError)
    return {
      _uid: '',
      component: '',
    };

  // in here we have a valid json representation of story content
  return parsed as Sb.StoryContent;
}

function isPreview() {
  if (typeof window !== 'undefined')
    return extractFromURL(window.location.href, '_storyblok') !== '';

  return false;
}

export function isStory<T extends Sb.Story = Sb.Story>(
  story: any,
  props?: (keyof Omit<T, 'id'>)[],
): story is Sb.Story {
  if (props) {
    return isObject<T>(story, ['id', ...props]);
  }

  return isObject<T>(story, ['id']);
}
