import StoryblokClient from 'storyblok-js-client';

import { extractFromURL, isStory } from '@hooks/useStoryblok';
import { Sb } from '@types';

const isEditableEnv = process.env.GATSBY_STORYBLOK_BRANCH_NAME === 'PREVIEW';

const Storyblok = new StoryblokClient({
  accessToken: process.env.GATSBY_STORYBLOK_ACCESS_TOKEN,
  cache: {
    clear: 'auto',
    type: 'memory',
  },
});

export function addBridge(callback: () => void) {
  const existingScript = document.getElementById('storyblokBridge');

  if (!existingScript) {
    const script = document.createElement('script');

    script.src = `//app.storyblok.com/f/storyblok-v2-latest.js`;

    script.id = 'storyblokBridge';

    document.body.appendChild(script);

    script.onload = () => {
      // once the scrip is loaded, init the event listeners
      callback();
    };
  } else {
    callback();
  }
}

export function initEventListeners(
  story: Sb.Story | undefined,
  setStory: (story: Sb.Story) => void,
) {
  initStoryblok(
    (sbBridge) => {
      sbBridge.on(['published', 'change'], () => {
        // reload project on save an publish
        window.location.reload();
      });

      sbBridge.on('input', (event: any) => {
        // live updates when editing
        if (isStory(story) && isEditableEnv) onInputChange(event, story, setStory);
      });

      // Call ping editor to see if in editor
      sbBridge.pingEditor(() => {
        if (sbBridge.isInEditor()) {
          // Load draft version of story
          sbBridge.enterEditmode();
        } else {
          // Load published version of story
        }
      });
    },
    [, 300],
  );
}

function onInputChange(
  event: StoryblokEventPayload | undefined,
  { content }: Sb.Story,
  setStory: (story: Sb.Story) => void,
): void {
  if (!event || !isStory(event.story)) return;

  if (!event.story || event.story.content._uid !== content._uid) return;

  if (!event.story.content.body) return;

  setStory(event.story);
}

export function get({ query }: Sb.SbBridgeGetProps): Promise<[Sb.Story | undefined, boolean]> {
  let options: Omit<Sb.SbBridgeQuery, 'slug'> = {
    /**
     * !Important - fetch the correct version (i.e. `draft`/`published`)
     * depending on the Storyblok space you're viewing. Use draft
     * only in `Development` space for development purposes.
     */
    ...query,
    version: isEditableEnv ? 'draft' : 'published',
    cv: query.cv || Date.now(),
  };

  // fetch story & add 5 retry attempts on fail
  return fetchLatestStoryFromCdn(query.slug, options, 5);
}

async function fetchLatestStoryFromCdn(
  path: string,
  options: Omit<Sb.SbBridgeQuery, 'slug'>,
  retryFetch: number,
): Promise<[Sb.Story | undefined, boolean]> {
  try {
    const { data } = await Storyblok.get(path, options);

    return [data.story as Sb.Story, false];
  } catch (error) {
    initStoryblok((sbBridge) => {
      if (error && sbBridge.isInEditor() && retryFetch >= 0) {
        console.warn(
          `Looks like the content is not published. Retrying again in development (draft) mode!`,
        );

        return fetchLatestStoryFromCdn(path, { ...options, version: 'draft' }, retryFetch - 1);
      }
    });

    console.error(
      `Unable to acquire latest story content from ${path}.\n\n============================================\n${error}\n============================================\nThere could be many reasons, but please verify the following:\n- see the CDN path is correct\n- check the slug of the story exists\n- check whether the content published?\n- check whether the Storyblok token correct (i.e preview or public token)`,
    );

    return [undefined, true];
  }
}

export function getCacheVersion() {
  return Storyblok.cacheVersion;
}

export function initStoryblok(
  cb: (sbBridge?: any) => void,
  props: [string | undefined, number] = [, 300],
): void {
  const [, waitTime] = props;

  const { StoryblokBridge } = window;

  const isStoryblokBridge = typeof StoryblokBridge !== 'undefined';

  setTimeout(() => {
    if (isStoryblokBridge) {
      return cb(new StoryblokBridge());
    }
  }, waitTime);
}

export function getQuery(path: string, query: string) {
  return path.includes(query || '');
}

export function isInEditor() {
  if (typeof window !== 'undefined')
    return extractFromURL(window.location.href, '_storyblok') !== '';

  return false;
}

export default Storyblok;
